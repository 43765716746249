$indigo: #00205c;
$indigo-400: #525985;
$indigo-300: #8080a2;
$indigo-200: #b6b5c9;

$cyan: #34c1d6;
$cyan-400: #67d1e0;
$cyan-300: #9ae0ea;
$cyan-200: #ccf0f5;

$primary: $indigo !default;
$info: $cyan !default;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-2xl: 0;

$tooltip-max-width: 250px;

$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$headings-font-family: 'FS Emeric', 'Open Sans', Arial, sans-serif !default;

$enable-reduced-motion: false;

$navbar-light-active-color: $primary;

$navbar-brand-height: 2.5rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// $body-bg: $light;
// $table-bg: $white;
// $input-bg: var(--bs-white);

$breadcrumb-font-size: $font-size-sm;
$breadcrumb-divider: "\00BB";

$info-text: $body-color;

$navbar-nav-link-padding-x: $spacer;

$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $primary;