@use 'sass:math';
@import 'variables';
@import '~bootstrap/scss/mixins';

.media-assets {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer;

  .media-asset {
    cursor: zoom-in;

    display: flex;
    flex-direction: column;
    margin: 0;
    color: var(--bs-body-color);
    flex-basis: 100%;

    border: 1px solid var(--bs-border-color-translucent);
    background-color: var(--exam-component-bg);
    padding: math.div($spacer, 2);

    @include media-breakpoint-up(md) {
      flex-basis: calc((100% - #{$spacer}) / 2);
    }

    figcaption {
      margin: 0;
    }

    > img {
      flex-shrink: 0;
      align-self: center;
    }
  }
}