@use 'sass:math';

@import '~typeahead-standalone/dist/basic';
@import 'variables';
@import "~bootstrap/scss/mixins";

.typeahead-standalone {
    --bs-dropdown-link-color: #{$dropdown-link-color};
    --bs-dropdown-link-hover-color: #{$dropdown-link-hover-color};
    --bs-dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
    --bs-dropdown-link-active-color: #{$dropdown-link-active-color};
    --bs-dropdown-link-active-bg: #{$dropdown-link-active-bg};
    --bs-dropdown-item-padding-x: #{$dropdown-item-padding-x};
    --bs-dropdown-item-padding-y: #{$dropdown-item-padding-y};

  .tt-list {
    border-color: var(--bs-border-color);

    div[class^=tt-] {
      padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    }

    .tt-suggestion {
      padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
      color: var(--bs-dropdown-link-color);

      &.tt-selected {
        color: var(--bs-dropdown-link-active-color);
        text-decoration: none;
        @include gradient-bg(var(--bs-dropdown-link-active-bg));
      }

      &:hover {
        color: var(--bs-dropdown-link-hover-color);
        @include gradient-bg(var(--bs-dropdown-link-hover-bg));
      }
    }
  }
}

// Fix error messages not displaying on typeahead fields
.typeahead-standalone ~ .invalid-feedback {
  display: block;
}
