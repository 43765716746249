@use "sass:math";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';

@mixin table-reflow() {

  &,
  thead,
  tbody,
  tfoot,
  th,
  td,
  tr {
    display: block;
  }

  td {
    border: none;
  }

  thead {
    @include visually-hidden();
  }

  tbody {
    tr {
      &+tr {
        margin-top: math.div($spacer, 3);
        padding-top: math.div($spacer, 2);
      }

      td,
      th {
        border-top: none;
        text-align: left;
        width: 100% !important;

        padding: math.div($spacer, 4) math.div($spacer, 2);

        &:last-child {
          padding-bottom: math.div($spacer, 2);
        }

        &:before {
          display: block;
          white-space: nowrap;
          font-weight: 700;
          content: attr(data-label);
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .table#{$infix}-reflow {
      @include table-reflow();
    }
  }
}