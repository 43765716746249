@use 'sass:math';
@import 'variables';

.taxonomy-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 0;
  li {
    padding-left: math.div($spacer, 2);
    border-left: math.div($spacer, 3) solid transparent;

    &.primary-tag {
      font-weight: 600;
      border-left-color: var(--bs-success);
    }
  }
}

.item-content {
  &, &.active {
    display: flex;
    flex-direction: column;
    gap: $spacer;
  }
}