@use 'sass:math';
@import 'variables';
@import "~bootstrap/scss/mixins";

$border-style: 1px solid var(--bs-border-color, #{$border-color});

.table-filters {
  background-color: var(--bs-gray-200, #{$gray-200});

  .row {
    --bs-gutter-x: #{math.div($spacer, 2)};
  }

  .search-box-col {
    padding-top: math.div($spacer, 2);
    padding-bottom: math.div($spacer, 2);
  }
  .filter-col {
    display: flex;
  }
  .filter-group {
    height: 100%;
    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-bottom: math.div($spacer, 2);
    }

    & + .filter-group {
      margin-left: 1px;
    }
  }
  .filter-toggles {
    font-size: 1rem;
    padding-top: 1px;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    > a {
      background-color: var(--bs-light, #{$light});
      color: var(--bs-body-color, #{$body-color});
      display: flex;
      width: 100%;
      min-width: 6.25rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      line-height: 1;
      padding: math.div($spacer, 2);
      position: relative;

      &, &:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 100%;
        position: absolute;
        right: math.div($spacer, 2);
        top: math.div($spacer, 2);
        background-color: transparent;
      }
      &.enabled {
        --fa-secondary-color: var(--bs-cyan);
        &:before {
          background-color: var(--bs-green, #{$green});
        }
      }

      &.active {
        background-color: var(--bs-white, #{$white});

        &:after {
          position: absolute;
          content: '';
          bottom: -1px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: var(--bs-white, #{$white});
          z-index: 2;
        }
      }
      i {
        line-height: 1.5;
      }
      span {
        font-size: 0.75rem;
      }
    }
  }

  .filter {
    position: fixed;
    margin-top: 1px;
    padding-top: $spacer;
    padding-bottom: $spacer;
    left: 0;
    right: 0;
    border-bottom: $border-style;
    background-color: var(--bs-white, #{$white});
    z-index: 2;
    box-shadow: 0 .5rem 0.5rem rgba(var(--bs-body-color-rgb), .15);
  }
}

.page-actions {
  display: flex;
  align-items: center;
  padding: math.div($spacer, 2);
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-bottom: 1px solid var(--bs-light);


  .nav-pills {
    --bs-nav-link-color: var(--bs-white);
    --bs-nav-link-hover-color: var(--bs-white);
    --bs-nav-pills-link-active-color: var(--bs-primary);
    --bs-nav-pills-link-active-bg: var(--bs-white);

    .nav-link {
      &:not(.active) {
        background-color: $indigo-300;
      }

      & + .nav-link {
        margin-left: math.div($spacer, 2);
      }
    }
  }
}

.table-header {
  background-color: var(--bs-primary, #{$primary});
  padding: math.div($spacer, 2);

  display: flex;

  flex-direction: column;
  gap: $spacer;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
  }

  > .btn-toolbar, > .btn {
    order: 2;
    align-self: flex-start;
    margin-left: auto;
  }

  > .btn-toolbar {
    gap: math.div($spacer, 3);
  }

  .table-header-text {
    color: var(--bs-white, #{$white});
  }
}

.table-pagination-top {
  .pagination {
    padding-top: math.div($spacer, 2);
  }
}

.table-pagination {
  background-color: var(--bs-primary, #{$primary});
  padding: math.div($spacer, 2);
}

.table-pagination-top,
.table-pagination {
  .pagination {
    margin: 0;

    a,
    .current,
    .gap {
      color: var(--bs-white, #{$white});
      background-color: $indigo-300;
      line-height: 1;
      padding: math.div($spacer, 3);

      &, &:hover {
        text-decoration: none;
      }

      & + a, & + .current, & + .gap {
        margin-left: math.div($spacer, 3);
      }

      &.current {
        background-color: var(--bs-white, #{$white});
        color: var(--bs-primary, #{$primary});
        font-style: normal;
      }
    }

    .disabled {
      display: none;
    }
  }
}

.ajax-table-loading {
  position: fixed;
  margin-top: 80px;
  left: 0;
  right: 0;
  z-index: 1030;
  text-align: center;
  color: $text-muted;

  > div {
    width: 200px;
    padding: 20px;
    background: var(--bs-white, #{$white});
    border: $border-style;
    margin: 0 auto;

    i {
      font-size: 2rem;
    }
  }
}

table.ajax-table {
  margin-bottom: 0;

  thead {
    th {
      background-color: var(--bs-primary, #{$primary});
      color: var(--bs-white, #{$white});
      font-weight: normal;

      a {
        color: var(--bs-white, #{$white});

        &.sort_link {
          &, &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.seach-box-tooltip {
  --bs-tooltip-color: #{$body-color};
  --bs-tooltip-bg: #{$cyan-200};
  --bs-tooltip-opacity: 1;
}

#filter-backdrop {
  z-index: -1;
  position: absolute;

  &.active {
    opacity: 0.3;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}